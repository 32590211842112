html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: white;
  color: black;
  font-family: "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 64px;
  padding: 0.5em 0.66em;
  line-height: 1.1;
}

@media (max-width: 640px) {
  body {
    font-size: 32px;
  }
}

.app {
  transform: translate(0, 0, 0);
}

.phrase {
  position: absolute;
}

.line {
  display: flex;
  flex-wrap: wrap;
}

.word {
  display: flex;
  flex-wrap: wrap;
  transform: translateZ(0);
}

.letter {
  transition-property: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.current .letter {
  opacity: 1;
}

.next .letter {
  opacity: 0;
}

.letter[data-state="adding"] {
  opacity: 1;
}

.letter[data-state="removing"] {
  opacity: 0;
}
